<template>
  <div class="login">
    <img :src="titImg" class="top-img" />
    <div class="bgColor"></div>
    <div class="card">
      <div class="card-tit">登录</div>
      <div class="input-item">
        <div class="item flex">
          <img src="@/assets/icon3.png" />
          <input placeholder="请输入账号" v-model="account" />
        </div>
        <div class="item flex">
          <img src="@/assets/icon4.png" />
          <input placeholder="请输入密码" type="password" v-model="password" />
        </div>
      </div>
      <div class="btn" @click="submit">提交</div>
    </div>
    <van-divider
      :style="{ color: '#8f8f8f', borderColor: '#8f8f8f', padding: '0 16px' }"
      >其他方式登陆</van-divider
    >
    <img @click="wxLogin" src="@/assets/wx-login.png" class="wx-login" />
  </div>
</template>
<script>
import AesEncode from "@/until/aesEncode";
import { login, config, wxlogin, positionDetail } from "@/api/apiData";
import { Icon, Toast, Divider } from "vant";
import GetQueryString from "@/until/getParams";

export default {
  components: {
    [Icon.name]: Icon,
    [Toast.name]: Toast,
    [Divider.name]: Divider,
  },
  data() {
    return {
      account: "",
      password: "",
      appid: "wx0d3c07901732c258",
      titImg: "",
    };
  },
  mounted() {
    window.document.title = "添加残疾人信息";
    let codeArr = GetQueryString("code");
    if (codeArr.length != 0) {
      let code = codeArr[codeArr.length - 1];
      wxlogin({ code: code }).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          this.$router.push("/form");
        } else {
          Toast.fail(res.msg);
          var url = window.location.href; //获取当前页面的url
          if (url.indexOf("code") != -1) {
            //判断是否存在参数
            url = url.replace(/(\?|#)[^'"]*/, "");
            window.location.href = url + "#/login";
          }
        }
      });
    }
    this.getPath();
  },
  methods: {
    // 获取首页图片
    getPath() {
      positionDetail({ position_id: 4 }).then((res) => {
        if (res.code == 1) {
          this.titImg = res.data.path;
        } else {
          Toast.fail(res.msg);
        }
      });
    },
    clearLink() {
      const str = location.href;
      const index = str.indexOf("tabId"); // 获取其地址
      let url = "";
      if (index != -1) {
        console.log("有值", str.substring(0, index));
        url = str.substring(0, index);
      } else {
        console.log("没有");
        url = location.href;
      }
      return url;
    },
    // 获取配置
    getConfig() {
      config().then((res) => {
        console.log(res.data);
        this.appid = res.data.appId;
      });
    },
    // 微信登陆
    wxLogin() {
      let href = window.location.href;
      let redirect_url = encodeURIComponent(href);
      let appid = this.appid;
      window.location.href = `https://ncymkj.doudou00.com/get-weixin-code.html
?appid=${appid}&redirect_uri=${redirect_url}&response_type=code&scope=snsapi_userinfo&state=STATE#wechat_re`;
    },
    submit() {
      let obj = {
        account: this.account,
        password: AesEncode(this.password),
      };
      login(obj).then((res) => {
        if (res.code == 1) {
          localStorage.setItem("token", res.data.token);
          this.$router.push("/form");
        } else {
          Toast.fail(res.msg);
        }
      });
    },
  },
};
</script>
<style lang="less" scoped>
.login {
  .top-img {
    width: 100%;
  }
  .card {
    background: white;
    border-radius: 3.2vw;
    margin: 0 3.2vw;
    padding: 7.333vw 4.133vw;
    position: relative;
    top: -5.333vw;
    .card-tit {
      color: #f69c00;
      font-size: 6.4vw;
      position: relative;
      width: fit-content;
      z-index: 2;
    }
    .card-tit::before {
      position: absolute;
      content: "";
      width: 100%;
      bottom: 0;
      left: 0;
      background: #d02510;
      height: 1.867vw;
      border-radius: 0.933vw;
      z-index: -1;
    }
    .input-item {
      margin: 9.333vw 0 2.667vw;
      .item {
        margin-bottom: 6.533vw;
        background: #f5f5f5;
        padding: 2.933vw;
        img {
          width: 8.533vw;
          height: 8.533vw;
        }
        input {
          border: none;
          outline: none;
          font-size: 4.933vw;
          background: none;
          margin-left: 4.8vw;
        }
      }
    }
    .btn {
      background: linear-gradient(to right, #f69e00, #fc6207);
      color: white;
      font-size: 5.227vw;
      text-align: center;
      line-height: 12.8vw;
      border-radius: 6.4vw;
      margin-top: 10.667vw;
    }
  }
  .wx-login {
    width: 60px;
    display: block;
    margin: 0 auto;
  }
}
</style>